@charset "UTF-8";

/* base16 eighties colorscheme by Chris Kempson (http://chriskempson.com) (https://github.com/chriskempson/base16) */
$base00 : #2d2d2d;
$base01 : #393939; 
$base02 : #515151; 
$base03 : #747369; 
$base04 : #a09f93; 
$base05 : #d3d0c8; 
$base06 : #e8e6df; 
$base07 : #f2f0ec; 
$base08 : #f2777a; 
$base09 : #f99157; 
$base0a : #ffcc66; 
$base0b : #99cc99; 
$base0c : #66cccc; 
$base0d : #6699cc; 
$base0e : #cc99cc; 
$base0f : #d27b53; 
/* base16+ pastel mods :-) */
$base0g : #d1e4dd;
$base0h : #cfe8cf;



/* light theme color */
$light-background: $base0g;
$light-background-secondary: #eaeaea;
$light-color: #222;
$light-color-secondary: #999;
$light-border-color: #dcdcdc;

/* dark theme colors */
$dark-background: #292a2d;
$dark-background-secondary: #3b3d42;
$dark-color: #a9a9b3;
$dark-color-secondary: #73747b;
$dark-border-color: #4a4b50;

$media-size-phone: "(max-width: 684px)";
$media-size-tablet: "(max-width: 900px)";

/* variables for js, must be the same as these in @custom-media queries */
:root {
  --phoneWidth: (max-width: 684px);
  --tabletWidth: (max-width: 900px);
}
